* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Encode Sans Expanded',
  sans-serif;
}
.container{
  position: absolute;
  top: 10%;
  left: 23%;
  width: 50%;
}
  
.text{
  display: inline;
  width: 100%;
}
  
.read-or-hide{
  color: rgb(192,192,192);
  cursor: pointer;
}

.mobile-nav {
	position: -webkit-sticky; /* Safari */
	position: sticky;
	top: 0;
}
.linkText{
  color : #00B74A !important;
  font-weight: bold; 
}
.mt-0 {
  margin-top: 0 !important;
}
.navbar-brand {
  padding-top: 15px;
  padding-bottom: 15px;
}
.modal {
  position: absolute;
  top: 10px;
  right: 100px;
  bottom: 0;
  left: 0;
  z-index: 10040;
  overflow: auto;
  overflow-y: auto;
}